@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700,800,900&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons&display=swap');

blockquote {
    border-left: 2px solid #ddd;
    margin-left: 0;
    margin-right: 0;
    padding-left: 10px;
    color: #aaa;
    font-style: italic;
}

blockquote[dir='rtl'] {
    border-left: none;
    padding-left: 0;
    padding-right: 10px;
    border-right: 2px solid #ddd;
}

.elfsight-app-dbaa5e56-01a2-4ecd-bda8-fb8f9dbf1119 {
    margin-bottom: 4px;
    background: hsl(206deg 100% 91.91%);
}

.elfsight-app-dbaa5e56-01a2-4ecd-bda8-fb8f9dbf1119 .eDJezm {
    justify-content: space-between !important;
    max-width: 1440px;
    width: 100%
}
